$primary-color: #fff;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Oswald", sans-serif;
}

body {
  background-color: $primary-color;
}
