.site-header {
  font-family: "Gabarito", sans-serif;
  min-height: 20vh;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../../assets/header-bg.webp");
  background-size: cover;
  background-position: center;
}
