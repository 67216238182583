#wycinka {
  background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)),
    url("../../assets/cards/wycinka.webp");
  background-size: cover;
  background-position: top;
}

#podnosniki {
  background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)),
    url("../../assets/cards/podnosniki.webp");
  background-size: cover;
  background-position: top;
}

#koparki {
  background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)),
    url("../../assets/cards/jcb.webp");
  background-size: cover;
  background-position: top;
}

#wysokosci {
  background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)),
    url("../../assets/cards/wysokosci.webp");
  background-size: cover;
  background-position: right;
}
